/* eslint-disable react/react-in-jsx-scope */
import App from "App";
import { CookieProvider } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const meta = REACT_ENV.REACT_APP_CONTENT_SECURITY_POLICY

ReactDOM.render(
    <CookieProvider>
        <Helmet>
            <meta httpEquiv="Content-Security-Policy" content={meta.replace(/self/gu, "'self'").replace(/unsafe-inline/gu, "'unsafe-inline'").replace(/unsafe-eval/gu, "'unsafe-eval'")} />
        </Helmet>

        <App />
    </CookieProvider>,
    document.querySelector("#root")
);

serviceWorker.unregister();
