/* eslint-disable react/forbid-component-props */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-newline */
/* eslint-disable prefer-const */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { toastError, toastLoading, toastUpdate } from "@3edges/utils/dist/toastify";
import { isEmpty, isWebLocalhost } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getStorage, selectedUser } from "cache";
import { UPDATE_TO_RELEASED_SPECIFIC_API } from "components/PanelDeploymentManagement/gql";
import { ALL_STATUS, UPDATE_STARTSTOP } from "components/PanelServer/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import
    {
        ClusterEnum,
        GetAllDataServers_niamDataServers as NiamDataServer,
        GetAllTenants_niamTenants as NiamTenant,
        Role,
        GetAllTenants_niamTenants_administrators as User,
        podEnum
    } from "types/operation-result-types";
import { isAdminOnThisDataserver, isOwner } from "utils";
import { Cluster, isSuperAdmin } from "utilsFn";
import
    {
        AccountTreeOutlinedStyled,
        AddDataServerIconStyled, ApartmentOutlinedStyled, Card, CardIconStyled,
        DelDataServerIconStyled,
        EditDataServerIconStyled,
        PlayApiServerIconStyled, ProcessingApiServerIconStyled, ShareDataServerIconStyled,
        StopApiServerIconStyled,
        TenantUsersIconStyled,
        TextBox,
        UpdateDataServerIconStyled
    } from "./styled";
import { REACT_ENV } from "environmentVariables";

interface IProps extends React.HTMLProps<HTMLDivElement> {
    isAdd?: boolean;
    smallCard?: boolean;
    text?: string;
    description?: string;
    belongsTo?: User[];
    icon?: string;
    tenantInfo?: NiamTenant;
    info?: NiamTenant | NiamDataServer;
    onMessageStatus?: (params: any) => void;
    onDelete?: () => void;
    onEdit?: () => void;
    onShare?: () => void;
    onShowUsers?: () => void;
    onClick?: (params: any) => any;
}

function PanelCard ({
    onClick,
    info,
    isAdd,
    smallCard,
    text,
    description,
    belongsTo,
    icon,
    tenantInfo,
    onMessageStatus,
    onDelete,
    onEdit,
    onShare,
    onShowUsers
}: IProps): React.ReactElement {
    const { t } = useTranslation();
    const superAdmin = isSuperAdmin();
    const currUser = getStorage<any>("user");
    let isCurrentAdmin = isAdminOnThisDataserver(tenantInfo?.administrators, currUser)

    const [btnDeleteDisabled, setBtnDeleteDisabled] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);

    const [podStatus, setPodStatus] = useState<podEnum>(podEnum.PROCESSING)
    const [clusterStatus, setClusterStatus] = useState<ClusterEnum>(ClusterEnum.PROCESSING)

    const [getAllStatus, { data: dataAllStatus }] = useLazyQuery(ALL_STATUS, { fetchPolicy: "no-cache" });
    const [updateStartStop, { data: dataUpdateStartStop }] = useMutation(UPDATE_STARTSTOP);

    const [updateToReleasedSpecificWorkload] = useMutation(UPDATE_TO_RELEASED_SPECIFIC_API);

    useEffect(() =>
    {
        let isMounted = true;
        async function fetchStatus()
        {
            if (info && !tenantInfo)
            {
                try
                {
                    if (isMounted)
                    {
                        getAllStatus({ variables: { serverID: info._id } })
                    }
                } catch {}
            }
        }

        fetchStatus();

        return () =>
        {
            isMounted = false
        };
    }, [info]);

    useEffect(() => {
        if (info && getAllStatus && dataUpdateStartStop && !tenantInfo && info["CLUSTER_SELECTED"] !== Cluster.LOCALHOST) {
            setPodStatus(dataUpdateStartStop.updateStartStop.status)
            onMessageStatus(dataUpdateStartStop.updateStartStop.status)
        }
    }, [info, dataUpdateStartStop])

    useEffect(() => {
        if (dataAllStatus) {
            const { getAllStatus } = dataAllStatus

            if (getAllStatus.isProcessing) {
                setPodStatus(podEnum.PROCESSING)
            } else {
                setPodStatus(getAllStatus.podStatus)
            }
            setClusterStatus(getAllStatus.clusterStatus)

            if (getAllStatus.podStatus === podEnum.PROCESSING || getAllStatus.clusterStatus === ClusterEnum.PROCESSING) {
                if (getAllStatus.clusterStatus === ClusterEnum.DISCONNECTED) {
                    setBtnDeleteDisabled(false)
                } else
                {
                    setBtnDeleteDisabled(info["CLUSTER_SELECTED"] !== Cluster.LOCALHOST ? true : false)
                }
            } else {
                setBtnDeleteDisabled(false)
            }

            let newUpdateNeeded = (new Date(getAllStatus.lastReleaseDate) <= new Date(getAllStatus.niamLastReleaseDate)) || isEmpty(getAllStatus.lastReleaseDate) ? true : false;
            if (getAllStatus.isProcessing) {
                newUpdateNeeded = false
            }

            setUpdateNeeded(newUpdateNeeded)
        }
    }, [dataAllStatus])

    // You are on Dataserver page
    if (!tenantInfo) {
        isCurrentAdmin = true;
    }

    const showOwner = (admins: User[]): string => {
        const collectionAdmins: User[] = [];

        if (admins) {
            admins.forEach((f) => {
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                if (f.roles && f.roles.includes(Role.SUPER_ADMIN)) {
                    return;
                }

                collectionAdmins.push(f);
            });

            if (collectionAdmins) {
                const sameUser = collectionAdmins.find((f) => f.email === selectedUser().email);

                if (collectionAdmins.length > 1) {
                    return `${collectionAdmins.length} ${t("panel:number_of_admins")}`;
                }

                if (!sameUser && collectionAdmins.length > 0) {
                    return collectionAdmins[0].email;
                }

                return `${t("panel:owner.yours")}`;
            }
        }

        return `${t("panel:owner.yours")}`;
    };

    const onDeleteEvent = () => {
        if (btnDeleteDisabled && !tenantInfo) {
            toastError(t("serverInfo:apiServer.cannot.delete"));
        } else {
            onDelete()
        }
    }

    const onStartStopApiServer = (serverID, start) => {
        setPodStatus(podEnum.PROCESSING)
        updateStartStop({ variables: { serverID, start } })
    }

    function TextBoxApiStatus(props: any)
    {
        const { color, children } = props

        return (
            <div data-cy={props['data-cy']} style={{
                position: 'absolute',
                left: 8,
                top: 24,
                color: color,
                fontSize: 12,
                display: 'flex'
            }}>
                <div style={{ marginRight: 5, height: 15, width: 15, backgroundColor: color, borderRadius: "50%" }} /> {children}
            </div>
        )
    }

    const redeploySpecificPod = async (clientNameGCP) => {
        setUpdateNeeded(false)
        const toastID = toastLoading()
        const domain = window.location.hostname

        const { data, errors }: any = await updateToReleasedSpecificWorkload({
            variables: {
                local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes(`.qa.${REACT_ENV.REACT_APP_DOMAIN}`) ?  Cluster.QA : Cluster.THREEEDGES,
                clientNameGCP
            }
        })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }

            setUpdateNeeded(true)
            return;
        }

        if (data.updateToReleasedSpecificWorkload.status) {
            setPodStatus(podEnum.PROCESSING)
            setUpdateNeeded(false)
            toastUpdate(toastID, t("validations:success.done"), "success")
        }
    }

    return (
        <div style={{ position: "relative", zIndex: 1 }}>
            {info && info["CLUSTER_SELECTED"] !== Cluster.LOCALHOST && clusterStatus === ClusterEnum.CONNECTED && info["autoDeployApiServer"] && (
                <>
                    {(isEmpty(podStatus) || podStatus === podEnum.STOPPED) && !isAdd && !tenantInfo && (
                        <PlayApiServerIconStyled>
                            <span data-cy={`cardPage_item_btnStartStopSTOPPED-${text}`} onClick={() => onStartStopApiServer(info._id, true)} title={t("dataserver:ModalDataServer.startBtn")} className="material-icons-outlined">play_circle</span>
                        </PlayApiServerIconStyled>
                    )}

                    {podStatus === podEnum.PROCESSING && !isAdd && !tenantInfo &&
                        <ProcessingApiServerIconStyled data-cy={`cardPage_item_btnStartStopPROCESSING-${text}`} title={t("dataserver:ModalDataServer.processing")} />
                    }

                    {podStatus === podEnum.RUNNING && !isAdd && !tenantInfo && (
                        <StopApiServerIconStyled>
                            <span data-cy={`cardPage_item_btnStartStopRUNNING-${text}`} onClick={() => onStartStopApiServer(info._id, false)} title={t("dataserver:ModalDataServer.stopBtn")} className="material-icons-outlined">stop_circle</span>
                        </StopApiServerIconStyled>
                    )}
                </>
            )}

            {!isWebLocalhost() && updateNeeded && !isAdd && !tenantInfo && (isCurrentAdmin || superAdmin) && (
                <UpdateDataServerIconStyled>
                    <span onClick={() => redeploySpecificPod(info?.["clientNameGCP"])} title={t("dataserver:ModalDataServer.updateBtn")} className="material-icons-outlined">manage_history</span>
                </UpdateDataServerIconStyled>
            )}

            {onShare && (isCurrentAdmin || superAdmin) && (isOwner(info, currUser) || superAdmin) && (
                <ShareDataServerIconStyled>
                    <span onClick={onShare} title={t("dataserver:ModalDataServer.shareBtn")} className="material-icons">group_add</span>
                </ShareDataServerIconStyled>
            )}

            {onShowUsers && (isCurrentAdmin || superAdmin) && (
                <TenantUsersIconStyled>
                    <span onClick={onShowUsers} title={t("dataserver:ModalDataServer.addPersonBtn")} className="material-icons">person_add</span>
                </TenantUsersIconStyled>
            )}

            {onEdit && (isCurrentAdmin || superAdmin) && (
                <EditDataServerIconStyled>
                    <span data-cy={`cardPage_item_btnEdit-${text}`} onClick={onEdit} title={t("dataserver:ModalDataServer.editBtn")} className="material-icons">edit</span>
                </EditDataServerIconStyled>
            )}

            {onDelete && (isCurrentAdmin || superAdmin) && (
                <DelDataServerIconStyled>
                    <span data-cy={`cardPage_item_btnDel-${text}`} onClick={onDeleteEvent} title={t("dataserver:ModalDataServer.deleteBtn")} className="material-icons">delete</span>
                </DelDataServerIconStyled>
            )}

            {!isAdd && !tenantInfo && (
                <TextBoxApiStatus
                    data-cy={podStatus === podEnum.RUNNING ? `cardPage_item_serverStatusUP-${text}` : `cardPage_item_serverStatusDOWN-${text}`}
                    color={podStatus === podEnum.RUNNING ? "#007700" :
                        podStatus === podEnum.STOPPED || clusterStatus === ClusterEnum.DISCONNECTED ? "#dd0000" : "orange"}
                >
                    {clusterStatus === ClusterEnum.DISCONNECTED ? clusterStatus : podStatus}
                </TextBoxApiStatus>
            )}

            <Card
                $isAdd={isAdd}
                $smallCard={smallCard}
                onClick={onClick}
                >
                <>
                    {isAdd
                        ? <AddDataServerIconStyled $smallCard={smallCard} />
                        : icon && <CardIconStyled src={icon} $smallCard={smallCard} />
                    }

                    {!isAdd && icon === "DataServerIcon" && <AccountTreeOutlinedStyled $smallCard={smallCard}><span className="material-icons">webhook</span></AccountTreeOutlinedStyled>}

                    {!isAdd && icon === "TenantIcon" && <ApartmentOutlinedStyled $smallCard={smallCard} />}

                    <TextBox isAdd={isAdd} $smallCard={smallCard} style={{ fontWeight: "bold" }}>
                        {text}
                    </TextBox>

                    {!isAdd && (
                        <TextBox fontSize="12px" style={{ color: "#AAA" }}>
                            {showOwner(belongsTo)}
                        </TextBox>
                    )}

                    <TextBox fontSize="12px" style={{ color: "#AAA" }}>
                        {description}
                    </TextBox>

                    {!isWebLocalhost() && updateNeeded && !isAdd && !tenantInfo && (isCurrentAdmin || superAdmin) &&
                        <TextBox fontSize="12px" style={{ color: "red", fontWeight: "bold" }}>{t("panel:apiServer.update.needed")}</TextBox>
                    }

                    {!isAdd && (
                        <div data-cy={`cardPage_item_randomName-${text}`} style={{ visibility: "hidden" }}>
                            {info?.["clientNameGCP"]}
                        </div>
                    )}
                </>
            </Card>
        </div>
    );
}

export default PanelCard;
